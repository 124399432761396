import { render, staticRenderFns } from "./LoadingSquare.vue?vue&type=template&id=03d6f495&scoped=true&lang=true&"
var script = {}
import style0 from "./LoadingSquare.vue?vue&type=style&index=0&id=03d6f495&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d6f495",
  null
  
)

export default component.exports